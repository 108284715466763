<template>
  <div v-if="!isAccess" class="alert alert-warning my-3">{{ messageError }}</div>
  <div v-else id="dashboard-content">
    <router-link :to="`/user/penilaian/detail/${id}`"><i class="fa fa-arrow-left mr-2"></i> Kembali</router-link>
    <nav class="mt-2">
      <ol class="breadcrumb p-0 bg-transparent">
        <li class="breadcrumb-item">
          <router-link to="/user/penilaian" class="text-muted">
            Daftar Penilaian Buku
          </router-link>
        </li>
        <li class="breadcrumb-item">
          <router-link :to="`/user/penilaian/detail/${id}`" class="text-muted">
            {{ historyRevision[0].payload.title }}
          </router-link>
        </li>
        <li class="breadcrumb-item active text-dark" aria-current="page">
          Riwayat Revisi
        </li>
      </ol>
    </nav>

    <h4>Riwayat Revisi Buku</h4>

    <!-- <div class="mb-4">
      <button class="btn bg-white shadow-sm text-muted">
        Filter Bulan <i class="fa fa-calendar-alt ml-2"></i>
      </button>
    </div> -->

    <div>
      <div
        v-for="history in historyRevision"
        :key="history.id"
        class="card mb-3 border-0 shadow-sm py-1 px-3"
      >
        <div class="card-body">
          <div class="d-flex align-items-center">
            <h5 class="mr-auto">Revisi</h5>
            <span class="text-muted">{{ updatedAt(history.created_at) }}</span>
          </div>
          <div class="row mt-3">
            <div class="col-12 mb-3">
              <div class="text-muted mb-2">Judul</div>
              <h6>{{ history.payload.title || "-" }}</h6>
            </div>
            <div class="col-lg-4 mb-3">
              <div class="text-muted mb-2">Mata Pelajaran</div>
              <h6>{{ history.payload.subject || "-" }}</h6>
            </div>
            <div class="col-lg-4 mb-3">
              <div class="text-muted mb-2">Tahun Terbit</div>
              <h6>{{ history.payload.publication_year || "-" }}</h6>
            </div>
            <div class="col-lg-4 mb-3">
              <div class="text-muted mb-2">Jenjang</div>
              <h6>{{ history.payload.level || "-" }}</h6>
            </div>
            <div class="col-lg-4 mb-3">
              <div class="text-muted mb-2">ISBN</div>
              <h6>-</h6>
            </div>
            <div class="col-lg-4 mb-3">
              <div class="text-muted mb-2">ID</div>
              <h6>{{ history.id || "-" }}</h6>
            </div>
            <div class="col-lg-4 mb-3">
              <div class="text-muted mb-2">Kode</div>
              <h6>-</h6>
            </div>
            <div class="col-lg-4 mb-3">
              <div class="text-muted mb-2">Edisi</div>
              <h6>{{ history.payload.edition || "-" }}</h6>
            </div>
            <div class="col-lg-4 mb-3">
              <div class="text-muted mb-2">Tipe Buku</div>
              <h6>{{ history.payload.book_type.toUpperCase() || "-" }}</h6>
            </div>
            <div class="col-lg-4 mb-3">
              <div class="text-muted mb-2">Total Halaman</div>
              <h6>{{ history.payload.total_content_page || "-" }}</h6>
            </div>
            <div class="col-lg-4 mb-3">
              <div class="text-muted mb-2">Ilustrator</div>
              <h6>{{ history.payload.illustrator || "-" }}</h6>
            </div>
            <div class="col-lg-4 mb-3">
              <div class="text-muted mb-2">Author</div>
              <h6>{{ history.payload.author || "-" }}</h6>
            </div>
            <div class="col-lg-4 mb-3">
              <div class="text-muted mb-2">Editor</div>
              <h6>
                {{ history.payload.editor ? history.payload.editor : "-" }}
              </h6>
            </div>
          </div>
          <hr />
          <div class="row mt-4">
            <div class="col-lg-4 mb-3">
              <div class="text-muted mb-2">File Original</div>
              <h6>{{ history.payload.file_original ? history.payload.file_original : "-" }}</h6>
            </div>
            <div class="col-lg-4 mb-3">
              <div class="text-muted mb-2">File Tanpa Identitas</div>
              <h6>{{ history.payload.file_no_identity ? history.payload.file_no_identity : "-" }}</h6>
            </div>
            <div class="col-lg-4 mb-3">
              <div class="text-muted mb-2">File Capaian Pembelajaran</div>
              <h6>{{ history.payload.file_capaian_pembelajaran ? history.payload.file_capaian_pembelajaran : "-" }}</h6>
            </div>
            <div class="col-lg-4 mb-3">
              <div class="text-muted mb-2">Surat Pernyataan Penulis</div>
              <h6>{{ history.payload.surat_pernyataan_penulis ? history.payload.surat_pernyataan_penulis : "-" }}</h6>
            </div>
            <div class="col-lg-4 mb-3">
              <div class="text-muted mb-2">Matrix Perbaikan</div>
              <h6>{{ history.payload.file_matrix_perbaikan ? history.payload.file_matrix_perbaikan : "-" }}</h6>
            </div>
          </div>
        </div>
      </div>
      <div class="d-flex mt-3 align-items-center">
        <router-link
          class="btn btn-outline-secondary"
          :to="`/user/penilaian/${historyRevision[0].payload.book_type}?id=${historyRevision[0].id}&event_id=${historyRevision[0].payload.assessment_event_id}`"
        >
          Revisi Buku
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import axios from "axios";
import { ASSESMENT_URL } from "../../../store";
moment.locale("id");

export default {
  name: "User-Penilaian-Revisi",
  data() {
    return {
      isAccess: true,
      messageError: '',
      id: null,
      historyRevision: [],
    };
  },
  methods: {
    getHistoryRevision(id) {
      axios
        .get(`${ASSESMENT_URL}/api/penilaian/book/revision/${id}`, {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        })
        .then((response) => {
          if (response.status == 200) this.historyRevision = response.data;
        })
        .catch((error) => {
          this.historyRevision = []
          if(error.response.status == 401) {
            this.messageError = "Buku tidak dapat diakses."
          }
          if(error.response.status == 404) {
            this.messageError = "Belum ada riwayat revisi buku."
          }
          this.isAccess = false
        });
    },
    updatedAt(date) {
      return `Diperbaharui pada ${moment(date).format("LLL")}`;
    },
  },
  created() {
    this.id = this.$route.params.id;
    this.getHistoryRevision(this.id);
  },
  beforeRouteEnter(_, __, next) {
    const { role_name } = JSON.parse(localStorage.getItem("user"));
    if (role_name === "Penerbit") next();
    else next("/user/home");
  },
};
</script>
